<template>
  <div >
    <div>

      <div>
        <h2 class="titlePage_subtitle titlePage">Accessories</h2>
        <portal to="modal-back-arrow">
          <span
            v-show="showBackButton"
            class="action-btn"
            @click="goBack"
          >
            <svg-icon name="chevron-left"
              class="lg"
            />
          </span>
        </portal>
        <transition name="fade">

          <start-screen-accessories :actionLinksPortalTarget="actionLinksPortalTarget" v-cloak @newAccessor="selectAccessor"
                                    v-if="step === steps.START"
          ></start-screen-accessories>
        </transition>

        <div  class="row" v-if="step !== steps.START">
          <div class="col-sm-12 col-md-3">
            <div class="d-flex flex-column nav-links pb-4">
              <button @click="navigateToFirstScreen" class="btn btn_link">All Accessories</button>
              <button @click="step = steps.TYPE" v-if="step >= steps.TYPE" class="btn btn_link" :class="{'active': step === steps.TYPE}">
                TYPE:
                <span class="d-block text_small ml-2">{{currentItemTypeStyle.typeDescription}} - {{ currentItemTypeStyle.styleDescription }}</span>
              </button>
              <button @click="navigateToItems" v-if="step >= steps.ITEM" class="btn btn_link" :class="{'active': step === steps.ITEM}">
                ITEM:
                <span class="d-block text_small ml-2">{{currentItem.name}}</span>
              </button>
              <button @click="step = steps.SIZE" v-if="step >= steps.SIZE" class="btn btn_link" :class="{'active': step === steps.SIZE}">
                SPECIFICATION:
              </button>
            </div>
          </div>
          <div class="col-sm-12 col-md-9 pb-4">
            <transition name="fade">
              <accessories-type :accessory="accessories"
                                v-if="step === steps.TYPE"
                                :actionLinksPortalTarget="actionLinksPortalTarget"
                                @selectType="selectTypeFrom"
              ></accessories-type>
            </transition>

            <transition name="fade">
              <accessories-item :accessories-items="accessoriesItems"
                                :type="measure_type"
                                v-if="step === steps.ITEM"
                                @selectItem="selectItemFrom"
              ></accessories-item>
            </transition>

            <transition name="fade">

              <accessories-size :mitreCut="mitreCut"
                                :accessory="dataModel"
                                :length="length"
                                :edit="editAccessory"
                                :colors="colors"
                                :order="order"
                                :package="true"
                                :type="measure_type"
                                v-if="step === steps.SIZE"
                                @create="sendAccessory"
                                @update="updateAccessory"
                                @close="closeUpdate"
                                :actionLinksPortalTarget="actionLinksPortalTarget"
                                :styleName="currentItemTypeStyle.styleDescription"
              ></accessories-size>
            </transition>

            <transition name="fade">

              <accessories-color :colors="this.colors"
                                 v-if="selectColor"
                                 @create="sendAccessory"
                                 @update="updateAccessory"
                                 @close="closeUpdate"
              ></accessories-color>
            </transition>
          </div>
        </div>


      </div>
    </div>
    <div class="modal modal_wrap" v-show="warning">
      <div class="modal_popup">
        <p class="text text_white">Chosen colour requires additional price. Please, click "I Accept" button if you want
          to continue.</p>
        <div class="justify-content-between d-flex">
          <button class="btn btn_transparent" @click="warning = !warning"><svg-icon name="solid/step-backward" /> Back</button>

          <button class="btn btn_transparent" @click="acceptUpdate" v-if="editAccessory"><svg-icon name="solid/check" /> I Accept</button>
          <button class="btn btn_transparent" @click="accept" v-else><svg-icon name="solid/check" /> I Accept</button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>

  import {defineAsyncComponent} from "vue";

  const StartScreenAccessories = defineAsyncComponent(() => import('../accessories/StartScreenAccessories'));
  const AccessoriesType = defineAsyncComponent(() => import('../accessories/AccessoriesType'));
  const AccessoriesItem = defineAsyncComponent(() => import('../accessories/AccessoriesItem'));
  const AccessoriesSize = defineAsyncComponent(() => import('../accessories/AccessoriesSize'));
  const AccessoriesColor = defineAsyncComponent(() => import('../accessories/AccessoriesColor'));
  import {mapGetters} from 'vuex';

  const steps = {
    START: -1,
    TYPE: 0,
    ITEM: 1,
    SIZE: 2,
  }


  export default {
    name: "Accessories",

    data() {
      return {
        step: steps.START,
        steps: steps,
        dataModel: {},
        index: '',

        start: true,
        select: false,
        selectType: false,
        selectItem: false,
        selectColor: false,

        measure_type: '',

        editAccessory: false,

        accessory: {
          typeName: '',
          name: '',
          type: '',
          measure_type: '',
          item: '',
          mitreCut: null,
          lengthVal: {},
          qty: '',
          color: '',
          option_name: 'ACC',
        },

        warning: false,
        updateAccessoryItem: false,
      }
    },

    props: {
      order: Boolean,
      package: Boolean,
      set: Boolean,
      actionLinksPortalTarget: String,
      showBackButton: {
        type: Boolean,
        default: false,
      }
    },

    watch: {
      mode: {
        handler: function (val, oldVal) {
          if(oldVal !== 'edit' && val === 'edit' && this.currentItemTypeStyle) {
            const { typeId, styleId } = this.currentItemTypeStyle
            this.$store.dispatch('accessories/getStyle', {item: styleId, type: typeId});
          }
        },
        immediate: true,
      },
      saving: function(savingNew, savingOld) {
        if(savingOld && !savingNew) {
          this.step = this.steps.START
        }
      },
      showAccessoriesType: {
        handler: function (val, oldVal) {
          // if(val) this.step = this.steps.TYPE
        },
        immediate: true,
        deep: true,
      },

      showAccessoriesItem: {
        handler: function (val, oldVal) {
          // if(val) this.step = this.steps.ITEM
        },
        immediate: true,
        deep: true,
      },

      showAccessoriesSize: {
        handler: function (val, oldVal) {
          if(val && this.mode ==='edit') this.step = this.steps.SIZE
        },
        immediate: true,
        deep: true,
      },

      step:{
        handler: function (val, oldVal) {
          if(val === this.steps.START){
            this.$store.dispatch('accessories/cleanup');
          }
        },
        immediate: true
      }

    },

    components: {
      StartScreenAccessories, AccessoriesType, AccessoriesItem, AccessoriesSize, AccessoriesColor
    },

    computed: {
      ...mapGetters({
        colors: 'colors/colors',
        accessories: 'accessories/accessories',
        accessoriesItems: 'accessories/accessoriesItems',
        mitreCut: 'accessories/mitreCut',
        length: 'accessories/length',
        currentItem: 'accessories/currentItem',
        mode : 'accessories/mode',
        saving: 'accessories/saving',
        saved: 'accessories/saved',
        currentItemTypeStyle: 'accessories/currentItemTypeStyle',
      }),

      showAccessoriesType() {
        return this.mode == 'new'  && this.currentItem.style == 0
      },

      showAccessoriesItem() {
        return this.mode == 'new' && this.currentItem.style != 0 && this.currentItem.grade == 0
      },

      showAccessoriesSize() {
        return this.mode != 'all' && this.currentItem.style != 0 && this.currentItem.grade != 0
      }
    },

    methods: {
      goBack() {
        const navigate = {
          [this.steps.START]: () => { this.$store.commit('orderPosition/goToStep', 'All Options') },
          [this.steps.SIZE]: this.navigateToItems,
          [this.steps.ITEM]: this.navigateToAccessoriesType,
          [this.steps.TYPE]: this.navigateToFirstScreen,
        }
        navigate[this.step]()
      },
      navigateToFirstScreen() {
        this.step = this.steps.START
        this.$store.commit('accessories/currentItem', {style: 0})
        this.$store.commit('accessories/mode', 'all');
      },

      navigateToAccessoriesType() {
        this.step = this.steps.TYPE;
      },

      navigateToItems() {
        this.step = this.steps.ITEM;
      },

      selectAccessor() {
          this.step = this.steps.TYPE
          this.$store.commit('accessories/mode', 'new');

      },

      selectTypeFrom(item, type) {
        this.step = this.steps.ITEM;
        this.$store.commit('accessories/mode', 'new');

        this.$store.dispatch('accessories/getStyle', {item: item.id, type: type.id});
      },

      selectItemFrom(item) {
        this.accessory.measure_type = item.measure_type
        this.step = this.steps.SIZE;
        this.$store.commit('accessories/setAccessory', item);
      },

      sendAccessory(item) {
        this.step = this.steps.START
        if (item.is_show_price_warning) {
          this.warning = !this.warning;
        }
        else {
          if(this.package || this.set){
            this.$store.commit('createdOrder/createAccessory', this.accessory);
            this.$store.commit('options/setSelected', this.accessory);
            this.$parent.itemOptions.ACC = false;
          }
          else{
            this.$store.commit('createdOrder/createAccessory', this.accessory);
            this.$parent.itemOptions.ACC = false;
            this.$store.dispatch('accessories/updateOrderPriceInOrder');
          }
        }
      },

      updateAccessory(item) {
        this.step = this.steps.START

        if (item.is_show_price_warning) {
          this.warning = !this.warning;
        }
        else{
          this.$store.commit('createdOrder/updateAccessory',
            {
              id: this.index,
              mitreCut: this.accessory.mitreCut,
              length: this.accessory.lengthVal,
              qty: this.accessory.qty,
              color: this.accessory.color,
            }
          );
          this.$parent.itemOptions.ACC = false;
        }
      },

      closePopup() {
        this.$emit('close');
      },

      closeUpdate() {
        this.step = this.steps.START;
        this.selectItem = false;
        this.start = true;
      },

      accept() {
        this.$store.commit('createdOrder/createAccessory', this.accessory);
        this.$parent.itemOptions.ACC = false;
      },

      acceptUpdate() {
        this.$store.commit('createdOrder/updateAccessory',
          {
            id: this.index,
            mitreCut: this.accessory.mitreCut,
            length: this.accessory.lengthVal,
            qty: this.accessory.qty,
            color: this.accessory.color,
          }
        );
        this.$parent.itemOptions.ACC = false;
      }

    },

    created() {
      this.$store.dispatch('accessories/getAccessories');
      if (this.order && !this.package) {
        return this.$store.dispatch('colors/getColors');
      }
      this.showBackButton = false
    },

    beforeDestroy() {
      this.$store.commit('options/setModalView', false);
    }
  }
</script>

<style scoped lang="scss">
  @import "../../../assets/scss/utils/vars";
  .modal__close {
    color: $green;
    top: 0.7rem;
  }

  .nav-links {
    .btn {
      margin-top: 0;
      margin-bottom: 0;
      border-radius: 0.42rem;
      color: $gray;
      text-decoration: none;
      text-align: left;
      &:hover {
        color: $navy;
      }
      &.active {
        color: $navy;
        background-color:  rgba($green, .3)
      }
      &:disabled {
        color: $lightGray;
      }
    }
  }
</style>
